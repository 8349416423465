<template>
  <loading v-if="loading" />

  <div v-else>
    <no-results
      v-if="!orderedNotes.length"
      :cta="{ label: 'Add note' }"
      icon="list-ul"
      title="No notes"
      message="No notes added to this site yet."
      @click="addNote"
    />

    <div v-else ref="notes" class="notes-wrapper">
      <div class="message is-dark has-margin-bottom-100">
        <div class="message-body has-padding-75 has-text-dark">
          <apart>
            <p>
              Use site notes to share
              <strong>non-sensitive</strong> information.
            </p>
            <button
              class="button is-dark is-rounded is-outlined"
              @click="addNote"
            >
              Add note
            </button>
          </apart>
        </div>
      </div>

      <note
        v-for="note in orderedNotes"
        :key="note._id"
        :timestamp="note.dateUpdated ? note.dateUpdated : note.dateCreated"
        :message="note.message"
        :author-name="note.authorName"
        :is-restricted="note.isRestricted"
        :is-author="isAgent || note.authorId === userId"
        @delete="deleteNote(note._id)"
        @update="updateNote(note)"
      />

      <load-more
        v-if="!pagination.complete"
        :loading="pagination.loading"
        @loadMore="getNotes"
      />
    </div>
  </div>
</template>

<script>
import { serverTimestamp } from "@firebase/firestore";
export default {
  name: "SiteNotes",
  props: {
    siteId: {
      type: String,
      required: true
    },
    adminContext: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pagination: {
        limit: 10,
        loading: false,
        complete: true
      },
      loading: true
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    user() {
      return this.$store.getters["user/user"]();
    },
    isAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    notes() {
      return this.$store.getters["sites/site/notes"](this.siteId);
    },
    orderedNotes() {
      return this.$_(this.notes)
        .orderBy(["isRestricted", "dateCreated"], ["desc", "desc"])
        .value();
    },
    oldestNote() {
      return this.orderedNotes.length ? this.$_.last(this.orderedNotes) : null;
    },
    newestNote() {
      return this.orderedNotes.length ? this.$_.first(this.orderedNotes) : null;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("sites/unobserveNotes", { siteId: this.siteId });
  },
  created() {
    this.observeNotes();
  },
  methods: {
    observeNotes() {
      this.$store
        .dispatch("sites/observeNotes", {
          siteId: this.siteId
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$toast.open({
            message: `Error getting notes`,
            position: "is-bottom",
            type: "is-danger"
          });
        });
    },
    deleteNote(noteId) {
      this.$store.dispatch("sites/deleteNote", {
        siteId: this.siteId,
        noteId: noteId
      });
    },
    addNote() {
      this.$modal.open({
        component: () => import("@shared/sites/_siteNoteModal"),
        parent: this,
        width: 600,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.siteId,
          defaultNote: {
            isRestricted: this.user.isAgent
          }
        }
      });
    },
    updateNote(note) {
      this.$modal.open({
        component: () => import("@shared/sites/_siteNoteModal"),
        parent: this,
        width: 600,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.siteId,
          noteId: note._id,
          note: {
            message: note.message,
            isRestricted: note.isRestricted,
            dateCreated: note.dateCreated,
            dateUpdated: serverTimestamp()
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

.notes-form {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 1em 0;
  border: 1px solid $light;
  border-radius: 5px;

  :nth-child(1) {
    flex-grow: 1;
  }

  button,
  textarea {
    border: none !important;
    box-shadow: 0 0 0 0 transparent !important;
    resize: none;
  }
  button {
    margin: 0 1em 0 0;
  }
}
</style>
